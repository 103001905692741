import React, { useState, useMemo, useEffect, useRef } from 'react';
import { formatCurrency, formatDate } from '../utils/formatters';
import * as XLSX from 'xlsx';
import logger from '../utils/logger';
import BalanceHeader from './BalanceHeader';
import SummaryBar from './SummaryBar';

export const getFullMonthName = (monthIndices) => {
  if (monthIndices.length === 0 ) return "<NO MONTH SELECTED>";
  const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthIndices.map(index => monthAbbreviations[index]);
};

const AccountList = ({ selectedNode, selectedMonth, selectedYears, viewMode, onBackToSummary, onBackToPrevious }) => {
  logger.log('AccountList - Received props:', { selectedNode, selectedMonth, selectedYears });
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'descending' });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [filteredNames, setFilteredNames] = useState([]);
  const [isSuggestionVisible, setIsSuggestionVisible] = useState(false);
  const suggestionRef = useRef(null);

  const items = selectedNode.individualAmounts;
  logger.log('AccountList - Individual amounts:', items);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
        setIsSuggestionVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSearchTerm('');
    setFilteredNames([]);
    setIsSuggestionVisible(false);
  }, [selectedNode]);

  const sortedItems = useMemo(() => {
    logger.log('AccountList - Sorting items');
    const sorted = [...items];
    if (sortConfig.key === 'date') {
      sorted.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortConfig.direction === 'ascending'
          ? dateA - dateB
          : dateB - dateA;
      });
    } else if (sortConfig.key === 'amount') {
      sorted.sort((a, b) => {
        return sortConfig.direction === 'ascending'
          ? a.amount - b.amount
          : b.amount - a.amount;
      });
    } else {
      // Generic comparison for name, type, num, split, and memo
      sorted.sort((a, b) => {
        let valueA = a[sortConfig.key];
        let valueB = b[sortConfig.key];
        
        // Convert to strings and handle null/undefined
        valueA = String(valueA || '');
        valueB = String(valueB || '');
        
        // Case-insensitive comparison
        if (sortConfig.direction === 'ascending') {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        } else {
          return valueB.toLowerCase().localeCompare(valueA.toLowerCase());
        }
      });
    }
    logger.log('AccountList - Sorted items:', sorted);
    return sorted;
  }, [items, sortConfig]);

  const searchFilteredItems = useMemo(() => {
    if (!searchTerm) return sortedItems;
    return sortedItems.filter(item => {
      const searchLower = searchTerm.toLowerCase();
      
      if (searchLower === '(empty)') {
        const value = item[searchField];
        return value === null || value === undefined || value === '';
      }
      
      const value = String(item[searchField] || '').toLowerCase();
      return value.includes(searchLower);
    });
  }, [sortedItems, searchTerm, searchField]);

  const displayItems = useMemo(() => {
    let displayItems = searchFilteredItems;
    return displayItems;
  }, [searchFilteredItems]);

  const getTotals = () => {
    const totals = displayItems.reduce((acc, item) => {
      acc.total += item.amount;
      if (item.amount >= 0) {
        acc.income += item.amount;
      } else {
        acc.expenses += Math.abs(item.amount);
      }
      return acc;
    }, { total: 0, expenses: 0, income: 0 });
    logger.log('AccountList - Totals:', totals);
    return totals;
  };

  const formatYears = (years) => {
    if (years.length === 0) return '';
    const sortedYears = [...years].sort((a, b) => a - b);
    return sortedYears.join(', ');
  };

  const handleSort = (key) => {
    logger.log('AccountList - Handling sort for key:', key);
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '↕';
  };

  const handleSearchChange = (e) => {
    const userInput = e.target.value;
    logger.log('AccountList - Search term changed:', userInput);
    setSearchTerm(userInput);
    if (userInput) {
      const uniqueNames = Array.from(
        new Set(items.map(item => item.name).filter(name => name && name.toLowerCase().includes(userInput.toLowerCase())))
      );
      setFilteredNames(uniqueNames);
      setIsSuggestionVisible(true);
    } else {
      setIsSuggestionVisible(false);
    }
  };

  const handleSearchClick = () => {
    logger.log('AccountList - Search button clicked');
    setIsSuggestionVisible(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      logger.log('AccountList - Enter key pressed');
      setIsSuggestionVisible(false);
    }
  };

  const exportToExcel = () => {
    logger.log('AccountList - Exporting data to Excel');
    const ws = XLSX.utils.json_to_sheet(displayItems);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");

    const period = selectedMonth.length !== 12 ? getFullMonthName(selectedMonth) : 'All months';
    const fileName = `${selectedNode.name} (account) | ${period}-${formatYears(selectedYears)}.xlsx`;

    XLSX.writeFile(wb, fileName);
  };

  const title = selectedMonth.length !== 12
    ? `${selectedNode.name} - ${getFullMonthName(selectedMonth)}, Years: ${formatYears(selectedYears)}`
    : `${selectedNode.name} - All months, Years: ${formatYears(selectedYears)}`;

  logger.log('AccountList - Rendering with title:', title);

  const { total, expenses, income } = getTotals();

  return (
    <div className="flex h-full">
      {viewMode === 'column' && (
        <SummaryBar 
          items={displayItems} 
          onItemClick={(value, field) => {
            setSearchTerm(value);
            setSearchField(field);
            setIsSuggestionVisible(false);
          }}
        />
      )}
      <div className="flex-1 flex flex-col pl-6">
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4">
            {viewMode === 'column' && (
              <div className="flex gap-2">
                <button
                  onClick={onBackToSummary}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Back to Summary
                </button>
                <button
                  onClick={onBackToPrevious}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  disabled={!selectedNode.parent}
                >
                  Back To Previous View
                </button>
              </div>
            )}
            <h3 className="text-4xl font-bold">{title}</h3>
          </div>
          <div className="flex justify-between items-start">
            <BalanceHeader
              total={total}
              expenses={-expenses}  // Negate expenses to match the format
              income={income}
              isColumnView={viewMode === 'column'}
            />
            <button
              onClick={exportToExcel}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded mt-2"
            >
              Export to Excel
            </button>
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <button
            onClick={() => {
              logger.log('AccountList - Reset button clicked');
              setSearchTerm('');
            }}
            className="bg-red-500 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Reset
          </button>
          <div className="relative w-full mr-2" ref={suggestionRef}>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              placeholder={`Search by ${searchField}...`}
              className="border rounded p-2 w-full"
            />
            {isSuggestionVisible && (
              <ul className="absolute z-10 w-full border rounded mt-1 bg-white">
                {filteredNames.map((name, index) => (
                  <li
                    key={index}
                    className="p-2 cursor-pointer"
                    onMouseDown={() => {
                      logger.log('AccountList - Suggestion selected:', name);
                      setSearchTerm(name);
                      setIsSuggestionVisible(false);
                    }}
                  >
                    {name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button
            onClick={handleSearchClick}
            className="bg-green-500 text-white font-bold py-2 px-4 rounded"
          >
            Search
          </button>
        </div>
        <div className="flex-1 overflow-y-auto">
          <table className="w-full whitespace-nowrap">
            <thead className="sticky top-0 bg-white">
              <tr>
                <th
                  className="text-left py-2 px-4 cursor-pointer"
                  onClick={() => handleSort('name')}
                >
                  Name {renderSortArrow('name')}
                </th>
                <th
                  className="text-right py-2 px-4 cursor-pointer"
                  onClick={() => handleSort('amount')}
                >
                  Amount {renderSortArrow('amount')}
                </th>
                <th
                  className="text-left py-2 px-4 cursor-pointer"
                  onClick={() => handleSort('date')}
                >
                  Date {renderSortArrow('date')}
                </th>
                <th
                  className="text-left py-2 px-4 cursor-pointer"
                  onClick={() => handleSort('type')}
                >
                  Type {renderSortArrow('type')}
                </th>
                <th
                  className="text-left py-2 px-4 cursor-pointer"
                  onClick={() => handleSort('num')}
                >
                  Num {renderSortArrow('num')}
                </th>
                <th
                  className="text-left py-2 px-4 cursor-pointer"
                  onClick={() => handleSort('split')}
                >
                  Split {renderSortArrow('split')}
                </th>
                <th
                  className="text-left py-2 px-4 cursor-pointer"
                  onClick={() => handleSort('memo')}
                >
                  Memo {renderSortArrow('memo')}
                </th>
                {/* Only show Investment Type when coming from InvestmentColumnView */}
                {selectedNode.fromInvestmentView ? (
                  <th
                    className="text-left py-2 px-4 cursor-pointer"
                    onClick={() => handleSort('investmentType')}
                  >
                    Investment Type {renderSortArrow('investmentType')}
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {displayItems.map((item, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 px-4">{item.name || ''}</td>
                  <td className="py-2 px-4 text-right">{formatCurrency(item.amount)}</td>
                  <td className="py-2 px-4">{formatDate(item.date)}</td>
                  <td className="py-2 px-4">{item.type || ''}</td>
                  <td className="py-2 px-4">{item.num || ''}</td>
                  <td className="py-2 px-4">{item.split || ''}</td>
                  <td className="py-2 px-4">{item.memo || ''}</td>
                  {selectedNode.fromInvestmentView ? (
                    <td className="py-2 px-4">
                      {item.investmentType}
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AccountList;
