import React, { useEffect, useState, useRef } from 'react';
import { formatDollarRounded } from '../utils/formatters';
import BalanceHeader from './BalanceHeader';

const InvestmentColumnView = ({
  selectedNode,
  selectedMonth,
  selectedYears,
  handleNodeClick,
  expandNode,
  onBackToSummary,
  onBackToPrevious,
}) => {
  const containerRef = useRef(null);
  const [columnDistribution, setColumnDistribution] = useState({ numColumns: 3, itemsPerCol: 0 });
  const [processedItems, setProcessedItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'total', direction: 'desc' });

  // Process investment data to separate contributions and distributions
  useEffect(() => {
    const processInvestmentData = () => {
      if (!selectedNode?.children) return [];

      const contributionsCategory = selectedNode.children.find(
        child => child.name === "Investment - Contributions"
      );
      const distributionsCategory = selectedNode.children.find(
        child => child.name === "Investment - Distributions"
      );

      // Get all unique items across both categories
      const allItems = new Map();

      // Process contributions
      if (contributionsCategory?.children) {
        contributionsCategory.children.forEach(item => {
          allItems.set(item.name, {
            name: item.name,
            contributionAmount: item.amount,
            distributionAmount: 0,
            originalItem: item
          });
        });
      }

      // Process distributions
      if (distributionsCategory?.children) {
        distributionsCategory.children.forEach(item => {
          const existing = allItems.get(item.name);
          if (existing) {
            existing.distributionAmount = item.amount;
          } else {
            allItems.set(item.name, {
              name: item.name,
              contributionAmount: 0,
              distributionAmount: item.amount,
              originalItem: item
            });
          }
        });
      }

      // Convert to array and sort by total amount
      return Array.from(allItems.values())
        .map(item => ({
          ...item,
          total: item.contributionAmount + item.distributionAmount,
          balance: item.contributionAmount + item.distributionAmount
        }));
    };

    const items = processInvestmentData();
    setProcessedItems(sortItems(items, sortConfig));
  }, [selectedNode, sortConfig]);

  const sortItems = (items, config) => {
    return [...items].sort((a, b) => {
      if (config.key === 'name') {
        const comparison = a.name.localeCompare(b.name);
        return config.direction === 'asc' ? comparison : -comparison;
      } else {
        let aValue, bValue;
        
        if (config.key === 'contributionAmount') {
          aValue = a.contributionAmount;
          bValue = b.contributionAmount;
        } else if (config.key === 'distributionAmount') {
          aValue = a.distributionAmount;
          bValue = b.distributionAmount;
        } else if (config.key === 'balance') {
          aValue = a.balance;
          bValue = b.balance;
        } else {
          aValue = a.total;
          bValue = b.total;
        }

        // For descending order (default), we want negative numbers first
        return config.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
    });
  };

  const handleSort = (key) => {
    const newConfig = {
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'desc' ? 'asc' : 'desc'
    };
    setSortConfig(newConfig);
    setProcessedItems(sortItems(processedItems, newConfig));
  };

  // Calculate optimal column distribution whenever container size changes or on zoom
  useEffect(() => {
    if (!containerRef.current) return;

    const calculateColumnDistribution = () => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const containerHeight = containerRect.height;
        const headerHeight = 40;
        const contentPadding = 32;
        const availableHeight = containerHeight - headerHeight - contentPadding;
        const itemHeight = 48;
        const bufferHeight = Math.min(availableHeight * 0.05, 48);
        const usableHeight = availableHeight - bufferHeight;
        
        const maxItemsPerColumn = Math.floor(usableHeight / itemHeight);
        
        let numColumns = 3;
        let itemsPerCol = Math.ceil(processedItems.length / numColumns);
        
        while (itemsPerCol > maxItemsPerColumn) {
          numColumns++;
          itemsPerCol = Math.ceil(processedItems.length / numColumns);
        }

        setColumnDistribution({ numColumns, itemsPerCol });
      }
    };

    const currentContainer = containerRef.current;
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(calculateColumnDistribution);
    });
    
    const handleZoom = () => {
      requestAnimationFrame(calculateColumnDistribution);
    };

    calculateColumnDistribution();

    if (currentContainer) {
      resizeObserver.observe(currentContainer);
      resizeObserver.observe(document.documentElement);
    }

    window.addEventListener('resize', handleZoom);
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleZoom);
      window.visualViewport.addEventListener('scroll', handleZoom);
    }

    window.addEventListener('wheel', (e) => {
      if (e.ctrlKey) {
        requestAnimationFrame(calculateColumnDistribution);
      }
    });

    return () => {
      if (currentContainer) {
        resizeObserver.unobserve(currentContainer);
        resizeObserver.unobserve(document.documentElement);
      }
      window.removeEventListener('resize', handleZoom);
      window.removeEventListener('wheel', handleZoom);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleZoom);
        window.visualViewport.removeEventListener('scroll', handleZoom);
      }
    };
  }, [processedItems]);

  const { numColumns, itemsPerCol } = columnDistribution;

  const calculateTotals = () => {
    if (!selectedNode?.children) return { contributions: 0, distributions: 0 };

    const contributionsCategory = selectedNode.children.find(
      child => child.name === "Investment - Contributions"
    );
    const distributionsCategory = selectedNode.children.find(
      child => child.name === "Investment - Distributions"
    );

    return {
      contributions: contributionsCategory?.amount || 0,
      distributions: distributionsCategory?.amount || 0
    };
  };

  const { contributions, distributions } = calculateTotals();
  const balance = contributions + distributions;  // This is already correct

  const handleItemClick = (item) => {
    if (item.originalItem) {
      handleNodeClick(item.originalItem);
      expandNode(item.originalItem);
    }
  };

  const renderSortArrow = (key) => {
    if (sortConfig.key !== key) {
      return <span className="text-gray-400 ml-2">↕</span>;
    }
    return <span className="text-white ml-2">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>;
  };

  const renderColumnHeader = () => (
    <div className="flex items-center py-2 px-4 bg-gray-700 text-white font-bold">
      <div 
        className="flex items-center cursor-pointer hover:bg-gray-600 px-2 py-1 rounded"
        style={{ width: '250px' }}
        onClick={() => handleSort('name')}
      >
        Category {renderSortArrow('name')}
      </div>
      <div 
        className="flex items-center justify-end cursor-pointer hover:bg-gray-600 px-2 py-1 rounded"
        style={{ width: '200px' }}
        onClick={() => handleSort('contributionAmount')}
      >
        <span>Contributions ($)</span>
        {renderSortArrow('contributionAmount')}
      </div>
      <div 
        className="flex items-center justify-end cursor-pointer hover:bg-gray-600 px-2 py-1 rounded"
        style={{ width: '200px' }}
        onClick={() => handleSort('distributionAmount')}
      >
        <span>Distributions ($)</span>
        {renderSortArrow('distributionAmount')}
      </div>
      <div 
        className="flex items-center justify-end cursor-pointer hover:bg-gray-600 px-2 py-1 rounded"
        style={{ width: '167px' }}
        onClick={() => handleSort('balance')}
      >
        <span>Balance ($)</span>
        {renderSortArrow('balance')}
      </div>
    </div>
  );

  const renderColumn = (columnIndex) => {
    const startIndex = columnIndex * itemsPerCol;
    const endIndex = Math.min((columnIndex + 1) * itemsPerCol, processedItems.length);
    const columnItems = processedItems.slice(startIndex, endIndex);

    return columnItems.map((item) => (
      <div
        key={item.name}
        className="flex items-center py-2 px-4 cursor-pointer hover:bg-gray-100 rounded transition-colors duration-150 ease-in-out"
        onClick={() => handleItemClick(item)}
      >
        <div className="truncate" style={{ width: '250px' }}>{item.name}</div>
        <div className="text-right" style={{ width: '200px' }}>
          {formatDollarRounded(item.contributionAmount).replace('$', '').trim()}
        </div>
        <div className="text-right" style={{ width: '200px' }}>
          {formatDollarRounded(item.distributionAmount).replace('$', '').trim()}
        </div>
        <div className="text-right" style={{ width: '167px' }}>
          {formatDollarRounded(item.balance).replace('$', '').trim()}
        </div>
      </div>
    ));
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center gap-4 mb-6">
        <div className="flex gap-2">
          <button
            onClick={onBackToSummary}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-150 ease-in-out"
          >
            Back to Summary
          </button>
          <button
            onClick={onBackToPrevious}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-150 ease-in-out"
            disabled={!selectedNode?.parent}
          >
            Back To Previous View
          </button>
        </div>
        <h3 className="text-4xl font-bold">
          {selectedNode?.name || ''} - {selectedMonth?.length === 12 ? 'All months' : selectedMonth?.map(m => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][m]).join(', ') || ''}, 
          Years: {selectedYears?.sort((a, b) => a - b).join(', ') || ''}
        </h3>
      </div>

      <div className="mb-6">
        <BalanceHeader
          total={balance}
          expenses={distributions}
          income={contributions}
          isColumnView={true}
        />
      </div>

      <div className="flex-1 overflow-x-auto">
        <div className="flex gap-6 h-full" style={{ width: 'max-content' }}>
          {[...Array(numColumns)].map((_, columnIndex) => (
            <div
              key={columnIndex}
              ref={columnIndex === 0 ? containerRef : null}
              className="border rounded-lg bg-white shadow flex flex-col h-full"
              style={{ width: '817px' }}
            >
              <div className="sticky top-0 z-10 rounded-t-lg overflow-hidden shadow-sm">
                {renderColumnHeader()}
              </div>
              <div className="flex-1 overflow-y-auto px-4 py-2">
                {renderColumn(columnIndex)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvestmentColumnView;
