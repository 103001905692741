/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { loadData, DEFAULT_YEAR } from '../data/data';
import Sidebar from './Sidebar';
import BarCharts from './BarCharts';
import ColumnView from './ColumnView';
import InvestmentColumnView from './InvestmentColumnView';
import AccountList from './AccountList';
import SearchBar from './SearchBar';
import NoDataSelected from './NoDataSelected';
import FileDataContext  from '../context/FileDataContext';
import { CircularProgress, Box } from '@mui/material';
import logger from '../utils/logger';

const FinancialDashboard = () => {
  const [dataProcessor, setDataProcessor] = useState(null);
  const [data, setData] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [lastSelectedNode, setLastSelectedNode] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [error, setError] = useState(null);
  const [selectedMonths, setSelectedMonths] = useState([...Array(12).keys()]);
  const [selectedYears, setSelectedYears] = useState([DEFAULT_YEAR]);
  const [allYears, setAllYears] = useState();
  const [viewMode, setViewMode] = useState('column'); // 'bar' or 'column'
  const [viewHistory, setViewHistory] = useState([]); // Track views visited since summary

  const { fileDataName } = useContext(FileDataContext);

  const updateDataRef = useRef();

  const getNodePath = useCallback((node) => {
    const path = [];
    let currentNode = node;
    while (currentNode) {
      path.unshift(currentNode.name);
      currentNode = currentNode.parent;
    }
    return path.join('/');
  }, []);

  const findNodeByPath = useCallback((root, path) => {
    logger.log('Finding node by path:', path);
    const parts = path.split('/');
    let currentNode = root;

    if (parts[0] !== root.name) {
      logger.log('Root node name does not match:', root.name);
      return null;
    }

    for (let i = 1; i < parts.length; i++) {
      const part = parts[i];
      logger.log('Searching for part:', part);
      if (!currentNode.children) {
        logger.log('No children found for current node');
        return null;
      }
      currentNode = currentNode.children.find(child => child.name === part);
      if (!currentNode) {
        logger.log('Child not found:', part);
        return null;
      }
      logger.log('Found child:', currentNode.name);
    }
    return currentNode;
  }, []);

  const addParentReferences = useCallback((node, parent = null) => {
    node.parent = parent;
    if (node.children) {
      node.children.forEach(child => addParentReferences(child, node));
    }
  }, []);

  const handleDateRangeChange = useCallback((start, end) => {
    logger.log('Date range changed:', { start, end });
    setDateRange({ start, end });
    
    // If both dates are null, reset to show all data
    if (!start && !end) {
      setSelectedMonths([...Array(12).keys()]);
      setSelectedYears([DEFAULT_YEAR]);
      return;
    }

    // Convert dates to months and years for filtering
    const startDate = start ? new Date(start) : null;
    const endDate = end ? new Date(end) : null;

    if (startDate && endDate) {
      const years = new Set();
      const months = new Set();
      
      // Get all months between start and end dates (inclusive)
      const current = new Date(startDate);
      while (current <= endDate) {
        years.add(current.getFullYear());
        months.add(current.getMonth());
        current.setDate(current.getDate() + 1);
      }

      setSelectedYears(Array.from(years));
      setSelectedMonths(Array.from(months));
    }
  }, [setDateRange, setSelectedMonths, setSelectedYears]); // Include state setters in dependencies

  const updateData = useCallback((processor, currentNodePath = null) => {
    logger.log('Updating data');
    try {
      if ((selectedYears.length === 0 || selectedMonths.length === 0) && (!dateRange.start || !dateRange.end)) {
        if (selectedNode) {
          setLastSelectedNode(selectedNode);
        }
        setData(null);
        setSelectedNode(null);
        setExpandedNodes({});
        return null;
      }

      let loadedData = processor(selectedYears, selectedMonths);
      
      logger.log('Loaded data:', loadedData);
  
      addParentReferences(loadedData);
      
      let newSelectedNode;
      if (lastSelectedNode) {
        const lastNodePath = getNodePath(lastSelectedNode);
        newSelectedNode = findNodeByPath(loadedData, lastNodePath) || loadedData;
        setLastSelectedNode(null);
      } else if (selectedNode) {
        const currentPath = getNodePath(selectedNode);
        newSelectedNode = findNodeByPath(loadedData, currentPath) || loadedData;
      } else if (currentNodePath) {
        newSelectedNode = findNodeByPath(loadedData, currentNodePath) || loadedData;
      } else {
        newSelectedNode = loadedData;
      }

      const newExpandedNodes = { ...expandedNodes };
      let node = newSelectedNode;
      while (node.parent) {
        newExpandedNodes[getNodePath(node)] = true;
        node = node.parent;
      }
      return { loadedData, newSelectedNode, newExpandedNodes };
      
    } catch (err) {
      console.error('Error processing data:', err);
      setError('Failed to process data. Please check the console for more details.');
      return null;
    }
  }, [expandedNodes, addParentReferences, findNodeByPath, getNodePath, selectedYears, selectedMonths, selectedNode, lastSelectedNode]);

  useEffect(() => {
    updateDataRef.current = updateData;
  }, [updateData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        logger.log('Fetching data...');
        const { computeForSelectYears, allYears } = await loadData(fileDataName);
        setAllYears(allYears)
        logger.log('Data processor received:', computeForSelectYears);
        setDataProcessor(() => computeForSelectYears);
        
        const result = updateDataRef.current(computeForSelectYears);
        if (result) {
          setData(result.loadedData);
          setSelectedNode(result.newSelectedNode);
          setExpandedNodes(result.newExpandedNodes);
        }
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load data. Please check the console for more details.');
      }
    };
    fetchData();
  }, [fileDataName, handleDateRangeChange]);

  useEffect(() => {
    if (dataProcessor) {
      const result = updateDataRef.current(dataProcessor);
      if (result) {
        setData(result.loadedData);
        setSelectedNode(result.newSelectedNode);
        setExpandedNodes(result.newExpandedNodes);
      }
    }
  }, [selectedYears, dataProcessor, selectedMonths]);

  const handleNodeClick = useCallback((node, fromInvestmentView = false) => {
    logger.log('Node clicked:', node);
    // Add current node to history before navigating
    if (selectedNode) {
      // If we're at summary level and navigating away, start fresh history
      if (selectedNode === data) {
        setViewHistory([selectedNode]);
      } else {
        // Limit history to max 3 entries to prevent memory bloat
        setViewHistory(prev => {
          const newHistory = [...prev, selectedNode];
          return newHistory.slice(-3); // Keep only the last 3 entries
        });
      }
    }

    if (fromInvestmentView && node.name !== "Investments") {
      // Find corresponding entries in both Distributions and Contributions
      const investmentsNode = data.children.find(child => child.name === "Investments");
      const distributionsNode = investmentsNode?.children.find(child => child.name === "Investment - Distributions");
      const contributionsNode = investmentsNode?.children.find(child => child.name === "Investment - Contributions");
      
      const distributionItems = distributionsNode?.children.find(child => child.name === node.name)?.individualAmounts || [];
      const contributionItems = contributionsNode?.children.find(child => child.name === node.name)?.individualAmounts || [];

      // Combine items and mark with their type
      const combinedItems = [
        ...distributionItems.map(item => ({ ...item, investmentType: 'Distributions' })),
        ...contributionItems.map(item => ({ ...item, investmentType: 'Contributions' }))
      ];

      // Create a new node with combined data
      const combinedNode = {
        ...node,
        individualAmounts: combinedItems,
        fromInvestmentView: true
      };
      
      setSelectedNode(combinedNode);
      expandNode(combinedNode);
    } else {
      setSelectedNode(node);
      expandNode(node);
    }
  }, [selectedNode, data]);

  const toggleExpand = useCallback((node) => {
    const nodePath = getNodePath(node);
    logger.log('Toggling expand for:', nodePath);
    setExpandedNodes(prev => ({
      ...prev,
      [nodePath]: !prev[nodePath]
    }));
  }, [getNodePath]);

  const expandNode = useCallback((node) => {
    logger.log('Expanding node:', node);
    setExpandedNodes(prev => {
      const newExpandedNodes = { ...prev };
      let currentNode = node;
      while (currentNode) {
        const nodePath = getNodePath(currentNode);
        newExpandedNodes[nodePath] = true;
        currentNode = currentNode.parent;
      }
      logger.log('New expanded nodes:', newExpandedNodes);
      return newExpandedNodes;
    });
  }, [getNodePath]);

  const handleSearchSelect = useCallback((result) => {
    logger.log('Search result selected:', result);
    const node = findNodeByPath(data, result.path);
    if (node) {
      logger.log('Node found:', node);
      setSelectedNode(node);
      expandNode(node);

      const nodeElement = document.getElementById(`node-${getNodePath(node)}`);
      if (nodeElement) {
        logger.log('Scrolling to node element');
        nodeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        logger.log('Node element not found in DOM');
      }
    } else {
      console.error('Node not found for path:', result.path);
    }
  }, [data, findNodeByPath, getNodePath, expandNode]);

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  if (!data && selectedYears.length > 0 && selectedMonths.length > 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  logger.log('Rendering dashboard with data:', data);
  logger.log('Selected node:', selectedNode);
  logger.log('Expanded nodes:', expandedNodes);

  const isAccountView = selectedNode && selectedNode.individualAmounts;

  return (
    <div className="flex flex-col h-screen">
      <div className="p-4 bg-gray-100">
        <SearchBar 
          data={data} 
          onSelect={handleSearchSelect} 
          viewMode={viewMode}
          onViewModeToggle={() => setViewMode(viewMode === 'bar' ? 'column' : 'bar')}
          onDateRangeChange={handleDateRangeChange}
          allYears={allYears?.sort((a, b) => a - b)}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {selectedYears.length === 0 || selectedMonths.length === 0 ? (
          <NoDataSelected />
        ) : (
          <>
            {viewMode === 'bar' && (
              <Sidebar
                allYears={allYears}
                data={data}
                selectedNode={selectedNode}
                expandedNodes={expandedNodes}
                handleNodeClick={handleNodeClick}
                toggleExpand={toggleExpand}
                getNodePath={getNodePath}
              />
            )}
            <div className="flex-1 flex flex-col overflow-hidden">
              <div className="flex-1 p-3 pr-5 pb-8 overflow-hidden">
                {isAccountView ? (
                  <AccountList
                    selectedNode={selectedNode}
                    selectedMonth={selectedMonths}
                    selectedYears={selectedYears}
                    viewMode={viewMode}
                    startDate={dateRange.start}
                    endDate={dateRange.end}
                    onBackToSummary={() => {
                      setViewHistory([]); // Clear history when returning to summary
                      setSelectedNode(data);
                    }}
                    onBackToPrevious={() => {
                      if (viewHistory.length > 0) {
                        const prevNode = viewHistory[viewHistory.length - 1];
                        setViewHistory(prev => prev.slice(0, -1));
                        setSelectedNode(prevNode);
                      } else if (selectedNode?.parent) {
                        setSelectedNode(selectedNode.parent);
                      }
                    }}
                  />
                ) : (
                  viewMode === 'bar' ? (
                    <BarCharts
                      selectedNode={selectedNode}
                      selectedMonth={selectedMonths}
                      handleNodeClick={handleNodeClick}
                      selectedYears={selectedYears}
                      expandNode={expandNode}
                    />
                  ) : (
                    selectedNode?.name === "Investments" || 
                    (selectedNode?.parent?.name === "Investment - Distributions" || 
                     selectedNode?.parent?.name === "Investment - Contributions") ? (
                      selectedNode?.name === "Investments" ? (
                        <InvestmentColumnView
                          selectedNode={selectedNode}
                          selectedMonth={selectedMonths}
                          selectedYears={selectedYears}
                          handleNodeClick={(node) => handleNodeClick(node, true)}
                          expandNode={expandNode}
                          onBackToSummary={() => {
                            setViewHistory([]); // Clear history when explicitly returning to summary
                            setSelectedNode(data);
                          }}
                          onBackToPrevious={() => {
                            if (viewHistory.length > 0) {
                              const prevNode = viewHistory[viewHistory.length - 1];
                              setViewHistory(prev => prev.slice(0, -1));
                              setSelectedNode(prevNode);
                            } else if (selectedNode?.parent) {
                              setSelectedNode(selectedNode.parent);
                            }
                          }}
                        />
                      ) : (
                        <AccountList
                          selectedNode={selectedNode}
                          selectedMonth={selectedMonths}
                          selectedYears={selectedYears}
                          viewMode={viewMode}
                          startDate={dateRange.start}
                          endDate={dateRange.end}
                          onBackToSummary={() => {
                            setViewHistory([]); // Clear history when returning to summary
                            setSelectedNode(data);
                          }}
                          onBackToPrevious={() => {
                            if (viewHistory.length > 0) {
                              const prevNode = viewHistory[viewHistory.length - 1];
                              setViewHistory(prev => prev.slice(0, -1));
                              setSelectedNode(prevNode);
                            } else if (selectedNode?.parent) {
                              setSelectedNode(selectedNode.parent);
                            }
                          }}
                        />
                      )
                    ) : (
                      <ColumnView
                        selectedNode={selectedNode}
                        selectedMonth={selectedMonths}
                        selectedYears={selectedYears}
                        handleNodeClick={handleNodeClick}
                        expandNode={expandNode}
                        onBackToSummary={() => {
                          setViewHistory([]); // Clear history when returning to summary
                          setSelectedNode(data);
                        }}
                        onBackToPrevious={() => {
                          if (viewHistory.length > 0) {
                            const prevNode = viewHistory[viewHistory.length - 1];
                            setViewHistory(prev => prev.slice(0, -1));
                            setSelectedNode(prevNode);
                          } else if (selectedNode?.parent) {
                            setSelectedNode(selectedNode.parent);
                          }
                        }}
                        onDateRangeChange={handleDateRangeChange}
                        startDate={dateRange.start}
                        endDate={dateRange.end}
                      />
                    )
                  )
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FinancialDashboard;
