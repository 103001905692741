import React, { useState, useEffect, useRef } from "react";
import logger from '../utils/logger';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const SearchBar = ({
  data,
  onSelect,
  viewMode,
  onViewModeToggle,
  onDateRangeChange,
  allYears
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [sortedYears, setSortedYears] = useState([]);
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const initialDateRangeSet = useRef(false);

  // Set min/max dates and initial date range when allYears changes
  useEffect(() => {
    if (allYears && allYears.length > 0) {
      const sorted = [...allYears].sort((a, b) => a - b);
      setSortedYears(sorted);
      const newMinDate = new Date(Math.min(...sorted), 0, 1);
      const newMaxDate = new Date(Math.max(...sorted), 11, 31);
      setMinDate(newMinDate);
      setMaxDate(newMaxDate);
      
      // Set initial dates only once
      if (!initialDateRangeSet.current) {
        setStartDate(newMinDate);
        setEndDate(newMaxDate);
        onDateRangeChange(newMinDate, newMaxDate);
        initialDateRangeSet.current = true;
      }
      
      console.log('Years available:', sorted);
    }
  }, [allYears, onDateRangeChange]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const flattenData = (node, path = []) => {
    let results = [];
    const newPath = [...path, node.name];
    let type;

    if (newPath.length === 1) {
      type = "Global";
    } else if (newPath.length === 2) {
      type = "Group";
    } else if (newPath.length === 3) {
      type = "Category";
    } else {
      type = "Account";
    }

    results.push({
      name: node.name,
      type,
      path: newPath.join("/"),
    });

    if (node.children) {
      node.children.forEach((child) => {
        results = results.concat(flattenData(child, newPath));
      });
    }
    return results;
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      const flatData = flattenData(data);
      logger.log("Flattened data:", flatData);
      const filtered = flatData.filter((item) =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
      logger.log("Filtered results:", filtered);
      setSearchResults(filtered);
      setIsDropdownVisible(true);
      setSelectedIndex(-1);
    } else {
      setSearchResults([]);
      setIsDropdownVisible(false);
      setSelectedIndex(-1);
    }
  };

  const handleSelect = (result) => {
    logger.log("Search result selected:", result);
    onSelect(result);
    setSearchTerm("");
    setIsDropdownVisible(false);
    setSelectedIndex(-1);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleKeyDown = (e) => {
    if (isDropdownVisible) {
      switch (e.key) {
        case "ArrowDown":
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex
          );
          break;
        case "ArrowUp":
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : prevIndex
          );
          break;
        case "Enter":
          e.preventDefault();
          if (selectedIndex >= 0 && selectedIndex < searchResults.length) {
            handleSelect(searchResults[selectedIndex]);
          }
          break;
        default:
          break;
      }
    }
  };

  const renderCustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="flex items-center justify-between px-2 py-2">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
        className={`${prevMonthButtonDisabled && "opacity-50"} hover:bg-gray-200 p-1 rounded`}
      >
        {"<"}
      </button>
      <div className="flex gap-2">
        <select
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(Number(value))}
          className="text-sm rounded border px-2 py-1"
        >
          {sortedYears.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select
          value={date.getMonth()}
          onChange={({ target: { value } }) => changeMonth(Number(value))}
          className="text-sm rounded border px-2 py-1"
        >
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={i}>
              {new Date(2000, i).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
        className={`${nextMonthButtonDisabled && "opacity-50"} hover:bg-gray-200 p-1 rounded`}
      >
        {">"}
      </button>
    </div>
  );

  return (
    <div className="flex items-center space-x-4">
      <div className="relative w-full max-w-md">
        <input
          ref={inputRef}
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          placeholder="Search for global, group, category, or account"
          className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {isDropdownVisible && searchResults.length > 0 && (
          <div
            ref={dropdownRef}
            className="absolute w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto z-10"
          >
            {searchResults.map((result, index) => (
              <div
                key={index}
                onClick={() => handleSelect(result)}
                className={`px-4 py-2 cursor-pointer ${
                  index === selectedIndex ? "bg-blue-100" : "hover:bg-gray-100"
                }`}
              >
                <span className="font-semibold">{result.name}</span>
                <span className="text-sm text-gray-500 ml-2">
                  ({result.type})
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <div className="relative z-50">
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                if (date && endDate) {
                  onDateRangeChange(date, endDate);
                }
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              placeholderText="Start Date"
              className="border rounded px-3 py-2 w-36"
              isClearable
              minDate={minDate}
              maxDate={maxDate}
              popperClassName="z-[1000]"
              renderCustomHeader={renderCustomHeader}
            />
          </div>
          <span className="text-gray-600">to</span>
          <div className="relative z-50">
            <DatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
                if (startDate && date) {
                  onDateRangeChange(startDate, date);
                }
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate || minDate}
              maxDate={maxDate}
              dateFormat="yyyy-MM-dd"
              placeholderText="End Date"
              className="border rounded px-3 py-2 w-36"
              isClearable
              popperClassName="z-[1000]"
              renderCustomHeader={renderCustomHeader}
            />
          </div>
          <button
            onClick={() => {
              if (minDate && maxDate) {
                setStartDate(minDate);
                setEndDate(maxDate);
                onDateRangeChange(minDate, maxDate);
              }
            }}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-150 ease-in-out"
          >
            Show All Time
          </button>
        </div>
        <button
          onClick={onViewModeToggle}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-150 ease-in-out"
        >
          {viewMode === 'bar' ? 'Switch to Column View' : 'Switch to Bar View'}
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
