import React, { useState, useCallback, useEffect } from 'react';
import { formatCurrency } from '../utils/formatters';

const SummaryBar = ({ items, onItemClick }) => {
  const [groupBy, setGroupBy] = useState('name'); // 'name' or 'memo'
  const [sortConfig, setSortConfig] = useState({ key: 'amount', direction: 'desc' }); // key: 'amount' or 'text', direction: 'asc' or 'desc'
  const [width, setWidth] = useState(() => {
    const savedWidth = localStorage.getItem('summaryBarWidth');
    return savedWidth ? parseInt(savedWidth) : 320;
  });
  const [isResizing, setIsResizing] = useState(false);

  const startResizing = useCallback((e) => {
    setIsResizing(true);
    e.preventDefault();
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    localStorage.setItem('summaryBarWidth', width.toString());
  }, [width]);

  const resize = useCallback((e) => {
    if (isResizing) {
      const newWidth = e.clientX;
      if (newWidth >= 200) {
        setWidth(newWidth);
      }
    }
  }, [isResizing]);

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', resize);
      document.addEventListener('mouseup', stopResizing);
    }

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', stopResizing);
    };
  }, [isResizing, resize, stopResizing]);

  const calculateSummary = () => {
    const groupedSums = items.reduce((acc, item) => {
      const key = item[groupBy] === null || item[groupBy] === undefined || item[groupBy] === '' 
        ? '(Empty)' 
        : String(item[groupBy]);
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += item.amount;
      return acc;
    }, {});

    // Convert to array and sort by absolute amount
    const sortedGroups = Object.entries(groupedSums)
      .map(([key, amount]) => ({ key, amount }))
      .sort((a, b) => {
        if (sortConfig.key === 'amount') {
          const comparison = Math.abs(b.amount) - Math.abs(a.amount);
          return sortConfig.direction === 'desc' ? comparison : -comparison;
        } else {
          const comparison = a.key.toLowerCase().localeCompare(b.key.toLowerCase());
          return sortConfig.direction === 'desc' ? -comparison : comparison;
        }
      });

    const totalSum = items.reduce((sum, item) => sum + item.amount, 0);

    return { sortedGroups, totalSum };
  };

  const renderSortArrow = (key) => {
    const isCurrentKey = (key === 'text' && sortConfig.key === 'text') || 
                        (key === 'amount' && sortConfig.key === 'amount');
    if (isCurrentKey) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '↕';
  };

  const { sortedGroups, totalSum } = calculateSummary();

  return (
    <div 
      style={{ width: `${width}px` }} 
      className="relative flex-shrink-0 bg-white border-r border-gray-200 p-4 overflow-y-auto"
    >
      <div className="mb-6">
        <h3 className="text-lg font-bold mb-2">Summary</h3>
        <div className="flex justify-between items-center mb-4">
          <span className="font-medium">Total:</span>
          <span className="font-bold">{formatCurrency(totalSum)}</span>
        </div>
        <div className="flex gap-2 mb-4 items-center">
          <button
            onClick={() => setGroupBy('name')}
            className={`px-3 py-1 rounded ${
              groupBy === 'name'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
          >
            Group by Name
          </button>
          <button
            onClick={() => setGroupBy('memo')}
            className={`px-3 py-1 rounded ${
              groupBy === 'memo'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
          >
            Group by Memo
          </button>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center p-2 font-medium bg-gray-100 rounded">
          <div 
            className="flex-1 mr-2 cursor-pointer"
            onClick={() => {
              setSortConfig(prev => ({
                key: 'text',
                direction: prev.key === 'text' && prev.direction === 'asc' ? 'desc' : 'asc'
              }));
            }}
          >
            {groupBy === 'name' ? 'Name' : 'Memo'} {renderSortArrow('text')}
          </div>
          <div 
            className="cursor-pointer"
            onClick={() => {
              setSortConfig(prev => ({
                key: 'amount',
                direction: prev.key === 'amount' && prev.direction === 'asc' ? 'desc' : 'asc'
              }));
            }}
          >
            Amount {renderSortArrow('amount')}
          </div>
        </div>
        <div className="space-y-1 mt-2">
          {sortedGroups.map(({ key, amount }) => (
            <div
              key={key}
              className="flex justify-between items-center p-2 hover:bg-gray-100 rounded cursor-pointer"
              onClick={() => onItemClick(key, groupBy)}
            >
              <span className="truncate flex-1 mr-2" title={key}>
                {key}
              </span>
              <span className="whitespace-nowrap font-medium">
                {formatCurrency(amount)}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div
        className="absolute top-0 right-0 bottom-0 w-1 cursor-col-resize bg-transparent hover:bg-gray-300 transition-colors"
        onMouseDown={startResizing}
      />
    </div>
  );
};

export default SummaryBar;
