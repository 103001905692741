import React, { useState, useMemo, useCallback } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
} from "recharts";
import { CircularProgress, Box } from "@mui/material";
import { formatDollarAmount } from "../utils/formatters";
import logger from '../utils/logger';
import { getFullMonthName } from '../components/AccountList';
import BalanceHeader from './BalanceHeader';

export const formatYears = (years) => {
  if (years.length === 0) return "";
  const sortedYears = [...years].sort((a, b) => a - b);
  return sortedYears.join(', ');
};

const BarCharts = ({
  selectedNode,
  selectedMonth,
  setSelectedMonth,
  handleNodeClick,
  selectedYears,
  expandNode,
}) => {
  const [loading, setLoading] = useState(true);

  const getChartData = useCallback((node) => {
    if (node.children) {
      const positiveData = [];
      const negativeData = [];

      node.children.forEach((child) => {
        const dataPoint = {
          name: child.name,
          value: child.amount || 0,
          absValue: Math.abs(child.amount || 0),
          node: child,
        };

        if (dataPoint.value >= 0) {
          positiveData.push(dataPoint);
        } else {
          negativeData.push(dataPoint);
        }
      });
      
      positiveData.sort((a, b) => b.value - a.value);
      negativeData.sort((a, b) => b.absValue - a.absValue);
      
      return [...positiveData, ...negativeData];
    }
    return [];
  }, []);

  const chartData = useMemo(() => {
    const data = getChartData(selectedNode);
  
    if (data.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000)
    }
  
    return data;
  }, [selectedNode, getChartData]);

  const handleBarClick = (entry) => {
    if (entry && entry.node) {
      handleNodeClick(entry.node);
      expandNode(entry.node);
    }
  };

  const CustomCursor = ({ x, y, width, height, payload }) => {
    if (!payload || !payload[0]) return null;

    return (
      <Rectangle
        fill="#cccccc"
        opacity={0.3}
        x={x}
        y={y}
        width={width}
        height={height}
        className="cursor-pointer"
        onClick={() => {
          logger.log("Custom cursor clicked:", payload[0].payload);
          handleBarClick(payload[0].payload);
        }}
        style={{ pointerEvents: "auto" }}
      />
    );
  };

  const CustomBar = (props) => {
    const { x, y, width, height, payload } = props;
    const barColor = payload.value >= 0 ? "rgb(119, 165, 90)" : "rgb(190, 75, 49)";

    return (
      <g
        onClick={() => {
          logger.log("CustomBar clicked:", payload);
          handleBarClick(payload);
        }}
        style={{ cursor: "pointer" }}
      >
        <Rectangle x={x} y={y} width={width} height={height} fill={barColor} />
        <text
          x={x + width / 2}
          y={y - 5}
          fill="black"
          textAnchor="middle"
          alignmentBaseline="bottom"
          fontSize={20}
          fontWeight="bold"
        >
          {formatDollarAmount(payload.value)}
        </text>
      </g>
    );
  };

  const calculateExpensesAndIncome = (data) => {
    return data.reduce(
      (acc, item) => {
        if (item.value >= 0) {
          acc.income += item.value;
        } else {
          acc.expenses += item.value;
        }
        return acc;
      },
      { expenses: 0, income: 0 }
    );
  };

  const renderBarChart = (data) => {
    const marginTop = 100;
    const marginBottom = 50;
    const marginRight = 30;
    const marginLeft = 0;
    const xAxisHeight = 160;
    const chartHeight = 500;
    const barWidth = 100;
    const chartWidth = Math.max(data.length * barWidth, 1000);

    const title = selectedMonth.length !== 12
      ? `${selectedNode.name} - ${getFullMonthName(selectedMonth)}, Years: ${formatYears(selectedYears)}`
      : `${selectedNode.name} - All months, Years: ${formatYears(selectedYears)}`;

    const { expenses, income } = calculateExpensesAndIncome(data);
    const balance = income + expenses;

    return (
      <div className="w-full">
        <h3 className="text-4xl font-bold mb-4">{title}</h3>
        <div style={{ height: `${chartHeight}px`, overflowX: "auto", position: "relative" }}>
          <div style={{ width: `${chartWidth}px`, height: "100%" }}>
            <ResponsiveContainer width="80%" height="100%">
              <BarChart
                layout="horizontal"
                data={data}
                margin={{
                  top: marginTop,
                  right: marginRight,
                  left: marginLeft,
                  bottom: marginBottom,
                }}
              >
                <XAxis
                  dataKey="name"
                  type="category"
                  angle={50}
                  textAnchor="start"
                  height={xAxisHeight}
                  interval={0}
                  tick={{
                    fontSize: 20,
                    fontWeight: "bold",
                    dy: 4,
                  }}
                />
                <YAxis
                  type="number"
                  tickFormatter={(value) => formatDollarAmount(value)}
                  tick={{
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                />
                <Tooltip
                  formatter={(value, name, props) => [
                    formatDollarAmount(props.payload.value),
                    name,
                  ]}
                  cursor={<CustomCursor />}
                />
                <Bar
                  dataKey="absValue"
                  shape={<CustomBar />}
                  minPointSize={5}
                  barSize={barWidth}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <hr style={{ borderTop: '2px solid red', margin: 0 }} /> {/* Debug line */}
        </div>
        <BalanceHeader
          total={balance}
          expenses={expenses}
          income={income}
        />
      </div>
    );
  };

  return (
    <div className="mb-4">
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        renderBarChart(chartData)
      )}
    </div>
  );
};

export default BarCharts;
