import React from 'react';

const formatAmount = (amount) => {
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount);
  const result = Math.round(absAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (isNegative ? '-$' : '$') + result;
};

const BalanceHeader = ({ total, expenses, income, isColumnView = false }) => {
  if (isColumnView) {
    return (
      <div className="mt-4">
        <div className="flex items-center gap-8" style={{ fontFamily: 'Arial' }}>
          <div className="flex items-center gap-2">
            <span style={{ color: 'rgb(76, 145, 65)' }} className="font-bold text-[20px]">Income: </span>
            <span style={{ color: 'rgb(76, 145, 65)' }} className="font-bold text-[20px]">{formatAmount(income)}</span>
          </div>
          <div className="flex items-center gap-2">
            <span style={{ color: 'rgb(170, 55, 35)' }} className="font-bold text-[20px]">Expense: </span>
            <span style={{ color: 'rgb(170, 55, 35)' }} className="font-bold text-[20px]">{formatAmount(expenses)}</span>
          </div>
          <div className="flex items-center gap-2">
            <span style={{ color: 'rgb(45, 100, 160)' }} className="font-bold text-[20px]">Balance: </span>
            <span style={{ color: 'rgb(45, 100, 160)' }} className="font-bold text-[20px]">{formatAmount(total)}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4">
      <div className="flex">
        <div style={{ fontFamily: 'Arial' }}>
          <div className="text-[20px]">
            <span style={{ color: 'rgb(76, 145, 65)' }} className="font-bold">Income: </span>
          </div>
          <div className="text-[20px]">
            <span style={{ color: 'rgb(170, 55, 35)' }} className="font-bold">Expense: </span>
          </div>
          <div className="text-[20px]">
            <span style={{ color: 'rgb(45, 100, 160)' }} className="font-bold">Balance: </span>
          </div>
        </div>
        <div style={{ fontFamily: 'Arial' }} className="ml-16">
          <div className="text-[20px]">
            <span style={{ color: 'rgb(76, 145, 65)' }} className="font-bold text-right w-56 inline-block">{formatAmount(income)}</span>
          </div>
          <div className="text-[20px]">
            <span style={{ color: 'rgb(170, 55, 35)' }} className="font-bold text-right w-56 inline-block">{formatAmount(expenses)}</span>
          </div>
          <div className="text-[20px]">
            <span style={{ color: 'rgb(45, 100, 160)' }} className="font-bold text-right w-56 inline-block">{formatAmount(total)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceHeader;
